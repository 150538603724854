class FilterBuilder {
    buildString(filtersArr) {
        let filters = [];

        var flat_price_ignore = $('.price-flat[data-price="flat"].ignore-filter-until-changed');
        var area_price_ignore = $('.price-area[data-price="area"].ignore-filter-until-changed');

        /* Убираем дубликаты из фильтра для районов или метро */
        if (this.hasActiveDistrict() || this.hasActiveMetro()) {
            filters = filters.filter((element, index) => {
                return filters.indexOf(element) !== index;
            });
        }

        for (let i = 0; i < filtersArr.length; i++) {
            let filterString = filtersArr[i].name;
            if (filtersArr[i].val !== undefined) {
                filterString += '|' + filtersArr[i].val;
            }

            // Если присутствует класс ignore-filter-until-changed, то не учитываем в фильтре пока пользователь сам не будет передвигать слайдер
            if (flat_price_ignore.length >= 2 && filtersArr[i].name === 'min_price_rub/int_band_filter' ||
                area_price_ignore.length >= 2 && filtersArr[i].name === 'min_price_rub_m2/int_band_filter') {
                continue;
            }

            // Если метро или район пустые, удаляем из фильтра
            if (filtersArr[i].name === 'district_id/int_multiple_filter' && filtersArr[i].val === '' ||
                filtersArr[i].name === 'metro_id/int_multiple_filter' && filtersArr[i].val === '') {
                continue;
            }

            filters.push(filterString);
        }

        filters = filters.join(';');

        let sortDirectionObj = $('[name=sort_direction]:checked');
        let sortTypeObj = $('[name=sort_type]:checked');
        let sortData = {
            type: '',
            direction: ''
        };

        if (sortTypeObj.length) {
            if (sortDirectionObj.length) {
                sortData.direction = sortDirectionObj.val();
            }

            let sortDataArr = sortTypeObj.val().split(':');

            sortData.type = sortDataArr[0];
            sortData.direction = sortDataArr[1] || 'asc';
        }
        return {
            filters: filters,
            sortDirection: sortData.direction,
            sortType: sortData.type
        }
    }

    issetFilter(filters, name) {
        return this.getFilter(filters, name).length;
    }

    getFilter(filters, name) {
        return filters.filter(function(item) {
            return item.name === name;
        });
    }

    getFilterIndex(filters, name) {
        let i = -1;
        filters.some(function(item, index) {
            if (item.name === name) {
                i = index;
                return true;
            }
        });

        return i;
    }

    /**
     * Проверяет выбрано ли метро в локациях
     *
     * @return boolean
     */
    hasActiveMetro() {
        return $('#location-metro').length && $('#location-metro').is(':checked') && $('#location-metro').val() != '';
    }

    /**
     * Проверяет выбран ли район в локациях
     *
     * @return boolean
     */
    hasActiveDistrict() {
        return $('#location-district').length && $('#location-district').is(':checked') && $('#location-district').val() != ''
    }

}

export class Filter {
    constructor(options) {
        this.filterBlockSelector = options.filterBlockSelector || false;
        this.filterResultSelector = options.filterResultSelector || false;
        this.filterFoundSelector = options.filterFoundSelector || false;
        this.showSelector = options.showSelector || false;
        this.loadMoreBtnSelector = options.loadMoreBtnSelector || false;
        this.filterShowBtnSelector = options.filterShowBtnSelector || false;
        this.toggleMapBtnSelector = options.toggleMapBtnSelector || false;
        this.filterMapResultSelector = options.filterMapResultSelector || false;
        this.mapStyleId = options.mapStyleId || false;
        this.onSendFilter = options.onSendFilter || false;
        this.tagsBlockSelector = options.tagsBlockSelector || false;
        this.loadersIcons = options.loadersIcons || false;
        this.isOneComplexMarkerInitOpen = options.isOneComplexMarkerInitOpen || false;
        this.usedGroups = [];
        this.filters = [];
        this.baseBreadcrumbs = [];
        this.prepareValue = null;
        this.isActiveFilterCallback = null;
        this.isMap = !$(this.filterResultSelector).length && $(this.filterMapResultSelector).length;
        this.map = null;
        this.filterBuilder = new FilterBuilder();
        this.init();
    }

    init() {
        this.filterBlock = this.filterBlockSelector ? $(this.filterBlockSelector) : undefined;
        this.filterUrl = this.filterBlock ? this.filterBlock.data('filter-url') : undefined;
        this.filterResultBlock = this.filterResultSelector ? $(this.filterResultSelector) : undefined;
        this.filterFoundBlock = this.filterFoundSelector ? $(this.filterFoundSelector) : undefined;
        this.showBloks = this.showSelector ? $(this.showSelector) : undefined;
        this.filterShowBtn = this.filterShowBtnSelector ? $(this.filterShowBtnSelector) : undefined;
        this.toggleMapBtn = this.toggleMapBtnSelector ? $(this.toggleMapBtnSelector) : undefined;
        this.tagsBlock = this.tagsBlockSelector ? $(this.tagsBlockSelector) : undefined;
        this.loadersIcons = this.loadersIcons ? $(this.loadersIcons) : $('[data-filter-loader]');
        this.events();

        if (this.filterBlock && this.tagsBlock) {
            let _this = this;

            this.filterBlock.find('input:not([data-ignore-filter]):checked').each(function() {
                _this.addTagToBlock(_this.generateFilterTagForElement($(this)), true);

            });

            this.filterBlock.find('input[type=hidden][data-tag-text]').each(function() {
                _this.addTagToBlock(_this.generateFilterTagForElement($(this)), true);

            });
            this.filterBlock.find('input[data-check-price-tag]:checked').each(function() {
                var input = $(this);
                var label = input.siblings('label');
                var val = input.val().split('-')
                var removeCallback = function(tag) {
                    _this.clearRange();
                    $(tag).remove();
                }
                _this.addTagToBlock(_this.generateFilterTag(label.html(), 'price-tag', 'set-price', true, removeCallback), true);
            });
        }
    }

    events() {
        let _this = this;

        if (_this.loadMoreBtnSelector) {
            $(document).on('click', _this.loadMoreBtnSelector, function(e) {
                let loadMoreBtn = $(_this.loadMoreBtnSelector);
                _this.loadMoreUrl = loadMoreBtn.attr('data-load-more-url')
                e.preventDefault();
                _this.loadMore(loadMoreBtn);
            });
        }

        $('.btn-clear-filter').click(function() {
            _this.clearFilter();
        })

        $(document).on('sendFilter', function() {
            _this.sendFilter();
        });

        if (_this.isMap) {
            _this.sendFilter(true);
        } else {
            if (this.toggleMapBtn && !_this.isMap) {
                this.toggleMapBtn.click(function() {
                    _this.isMap = !_this.isMap;
                    if (_this.isMap) {
                        _this.loadMapResults();
                    } else {
                        _this.sendFilter();
                    }
                });
            }
        }

        if (this.tagsBlock && this.filterBlock) {
            this.filterBlock.find('input:not([data-ignore-filter])').change(function() {
                let filterName = $(this).attr('name'),
                    filterValue = $(this).val();
                let tag = _this.getFilterTag(filterName, filterValue);
                if ($(this).prop('checked')) {
                    _this.addTagToBlock(_this.generateFilterTagForElement($(this)), true);
                } else {
                    tag.remove();
                }
                _this.prepareTagItems();
            });
        }

        $(document).on('addFilterTag', function(e, data) {
            _this.addTagToBlock(_this.generateFilterTag(data.text, data.filterName, data.filterValue,
                data.addCloseBtn, data.removeCallback), true);
        });

        $(document).on('removeFilterTag', function(e, data) {
            let tag = _this.getFilterTag(data.filterName, data.filterValue);
            tag.remove();
        });

        this.hideDOMelement();
    }

    clearFilter() {
        $('.filter__tags-item').remove();
        $('#created-dynamic-filters').empty();
        this.filterBlock.find('.tags__item input').prop('checked', false);
        this.filterBlock.find('input:not([data-ignore-filter]):checked').prop('checked', false);
        this.clearRange();
        this.IsClearFilter = true;
        this.sendFilter();
        history.pushState('', '', $('.clear-filter').attr('href'))
    }

    clearRange() {
        var rangeSlider = $('.filters-price-range')
        rangeSlider.each(function(index, element) {
            $(element)[0].noUiSlider.set([
                $(element)[0].noUiSlider.options.range['min'],
                $(element)[0].noUiSlider.options.range['max']
            ]);
        });

    }

    filterAjax(url, successCallback) {
        if (this.isMap) {
            url = new URL(url);
            url.searchParams.set('isMap', '1');
            url = url.toString();
        }

        if (url) {
            $.ajax({
                url: url,
                success: function(json) {
                    successCallback(json);
                    if (json.selection === "") {
                        main.setMetaData();
                    }
                    main.selectionsUpdateBlocks(json.selection);
                    // complexesInit.complexSlider();
                    // fast_background.update();
                    // main.mobileSliderShow();
                }
            })
        }
    }

    refreshMeta(selection) {
        var isComplexPage = !(document.querySelector("#filter-result") && document.querySelector("#filter-map"));
        if ((!isComplexPage && selection.title !== '') ||
            (isComplexPage && !this.isMap && selection.title !== '')) {
            $('title').html(selection.title)
        } else if ((!isComplexPage && selection.meta.title !== '') ||
            (isComplexPage && !this.isMap && selection.meta.title !== '')) {
            $('title').html(selection.meta.title)
        }
        if ((!isComplexPage && selection.description !== '') ||
            (isComplexPage && !this.isMap && selection.description !== '')) {
            $('meta[name="description"]').attr('content', selection.description);
        } else if ((!isComplexPage && selection.meta.description !== '') ||
            (isComplexPage && !this.isMap && selection.meta.description !== '')) {
            $('meta[name="description"]').attr('content', selection.meta.description);
        }
        if ((!isComplexPage && selection.h1 !== '') ||
            (isComplexPage && !this.isMap && selection.h1 !== '')) {
            var h1 = '<h1 class="block__title block__title_big">' + selection.h1 + '</h1>';
            $('#head-replaceable').html(h1);

        }
        if (selection.breadcrumbs.length > 0) {
            this.renderBreadcrumbs(selection.breadcrumbs);
        }
        if (selection.slug !== '') {
            history.pushState('', '', '/' + gInitialData.domainConfig.route_params.aliases.complexes + '/' + selection.slug)
        }
    }

    renderBreadcrumbs(breadcrumbs) {
        var crumbContainer = $('.bread-crumbs');
        if (crumbContainer.length && !crumbContainer.hasClass('bread-crumbs--not-editable')) {
            this.baseBreadcrumbs = this.getBaseCrumbs();
            crumbContainer.empty();
            if (!(breadcrumbs.length > 0)) {
                breadcrumbs = this.baseBreadcrumbs;
            }
            $.each(breadcrumbs, function (index, element) {

                var breadCrumbItem = '';
                breadCrumbItem = '<li  class="bread-crumbs__item breadcrumbs-replace" itemscope="" itemprop="itemListElement" itemtype="http://schema.org/ListItem">';
                if (index != breadcrumbs.length - 1) {
                    breadCrumbItem += '<a class="bread-crumbs__item-data" itemprop="item" href="' + element.link + '"><span class="bread-crumbs__item-data" itemprop="name">' + element.title + '</span></a>';
                } else {
                    breadCrumbItem += '<span class="bread-crumbs__item-data" itemprop="name">' + element.title + '</span>';
                }
                breadCrumbItem += '<meta itemprop="position" content="' + (Number(index) + 1) + '">';
                breadCrumbItem += '</li>';
                crumbContainer.append(breadCrumbItem);
            })
        }
    }

    getBaseCrumbs() {
        var crumbs = $('.bread-crumbs__item')
        var baseBreadcrumbs = [];
        crumbs.each((key, crumb) => {
            baseBreadcrumbs[key] = {
                title: $(crumb).find('.bread-crumbs__item-data').html(),
                link: $(crumb).find('.bread-crumbs__item-data').attr('href'),
            }
        });
        return baseBreadcrumbs;
    };

    checkDeletedLastFilter(filters) {
        var filtersArr = [];
        var minMax = this.getMinMaxForPrices();
        filters.forEach(function(element, index) {
            // if (element.name === gInitialData.complexDefaultPriceField + '/int_band_filter' || element.name === gInitialData.complexDefaultPriceSpaceField + '/int_band_filter') {
            //     var minMaxFilter = element.val.split(":");
            //     if (minMaxFilter[0] != minMax[element.name].min || minMaxFilter[1] != minMax[element.name].max) {
            //         filtersArr.push(element);
            //     }
            // } else {
                filtersArr.push(element)
            // }
        })
        return filtersArr.length === 0
    }

    getMinMaxForPrices() {
        var rangePrices = $('#price-range')
        var areaPrices = $('#price-range-area')
        // var priceName = gInitialData.complexDefaultPriceField + '/int_band_filter'
        // var priceAreaName = gInitialData.complexDefaultPriceSpaceField + '/int_band_filter'

        return {
            // [priceName]: {
            //     'min': rangePrices.data('min'),
            //     'max': rangePrices.data('max'),
            // },
            // [priceAreaName]: {
            //     'min': areaPrices.data('min'),
            //     'max': areaPrices.data('max'),
            // }
        }
    }


    refreshFilters() {
        // clearTimeout(main.filterHelper.timerId)
        let _this = this;
        this.filters = [];
        this.usedGroups = [];
        if (this.filterBlock) {
            this.filterBlock.find('input').each(function() {
                _this.addToFilter($(this))
            });
        }
        if (_this.checkDeletedLastFilter(_this.filters)) {
            _this.filters = [];

            // main.setMetaData();
            _this.renderBreadcrumbs(_this.baseBreadcrumbs)
            history.pushState('', '', $('.clear-filter').attr('href'))
        }
    }

    addToFilter(el) {
        if (!this.needAddToFilter(el)) {
            return;
        }
        let _this = this;

        let group = el.data('filter-group');
        if (group !== undefined) {
            if (this.usedGroups.indexOf(group) !== -1) {
                return;
            }

            this.usedGroups.push(group);
            _this.filters.push({ name: 'begin_or_group' });
            if (this.filterBlock) {
                this.filterBlock.find('[data-filter-group="' + group + '"]').each(function() {
                    _this.pushToFilters($(this));
                });
            }

            _this.filters.push({ name: 'end_or_group' });
        } else {
            _this.pushToFilters(el)
        }
    }

    pushToFilters(el) {
        if (!this.needAddToFilter(el)) {
            return;
        }

        let _this = this;
        let push = function(value, name) {
            name = name || el.attr('name');
            value = value || el.val();

            if (typeof _this.prepareValue === 'function') {
                let tmpValue = _this.prepareValue(name, value);
                if (tmpValue) {
                    value = tmpValue;
                }
            }

            if (_this.filterBuilder.issetFilter(_this.filters, name)) {
                let index = _this.filterBuilder.getFilterIndex(_this.filters, name);
                let currentVal = _this.filters[index].val;
                let delimiter;
                if (name.indexOf('int_multiple_band_filter') !== -1 ||
                    name.indexOf('int_multiple_band_exists_filter') !== -1 ||
                    name.indexOf('lots_rooms/int_multiple_band_strict_filter') !== -1) {
                    delimiter = ',';
                } else {
                    delimiter = ':';
                }

                currentVal += delimiter + value;
                _this.filters[index].val = currentVal;
            } else {
                _this.filters.push({
                    name: name,
                    val: value
                });
            }
        }

        if (el.is('[type=text]')) {
            let value = el.val();
            if (el.attr('name').indexOf('int_') !== -1) {
                value = value.replace(/[^0-9]/g, "");
            }

            push(value);
        } else {
            push();
        }

        this.hideDOMelement();
    }

    needAddToFilter(el) {
        if (el.is('[data-ignore-filter]') || el.attr('name') === undefined || ['sort_type', 'sort_direction'].indexOf(el.attr('name')) !== -1) {
            return false;
        }
        // var priceFilterName = gInitialData.complexDefaultPriceField + '/int_band_filter';
        // var priceSpaceFilterName = gInitialData.complexDefaultPriceSpaceField + '/int_band_filter';
        // if (el[0].name === priceFilterName || el.name === priceSpaceFilterName) {
        //     if (el[0].value === "") {
        //         return false;
        //     }
        // }
        if (typeof this.isActiveFilterCallback === 'function') {

            if (!this.isActiveFilterCallback(el)) {
                return false;
            }
        }

        if (el.is('[type=radio]') || el.is('[type=checkbox]')) {
            if (el.is(':checked')) {
                return true;
            }
        } else if (el.is('[type=text]')) {
            if (el.val().length) {
                return true;
            }
        } else if (el.is('[type=hidden]')) {
            return true;
        }

        return false;
    }

    getFiltersString() {
        let searchParams = new URLSearchParams(window.location.search);
        let filterData = this.filterBuilder.buildString(this.filters);
        searchParams.set('filters', filterData.filters);

        if (filterData.sortDirection !== null) {
            searchParams.set('sort_direction', filterData.sortDirection);
        }

        if (filterData.sortType !== null) {
            searchParams.set('sort_type', filterData.sortType);
        }

        return searchParams.toString();
    }

    switchLoaders(isShow) {

        if (isShow) {
            this.loadersIcons.removeClass('hidden-block');
            $('[data-filter-loading-hidden]').addClass('hidden-block');
        } else {
            this.loadersIcons.addClass('hidden-block');
            $('[data-filter-loading-hidden]').removeClass('hidden-block');
        }

    }

    sendFilter(fastSend = false) {
        let _this = this;
        this.refreshFilters();
        if (this.onSendFilter) {
            this.onSendFilter();
        } else {
            this.switchLoaders(true);
            var filtersStr = this.getFiltersString();
            var url = ''
            if (_this.IsClearFilter) {
                url = _this.filterUrl;
            } else {
                url = _this.filterUrl + '?' + this.getFiltersString();
            }

            // if (fastSend) {
            _this.sendFilterDelayed(_this, url, filtersStr)
            // } else {
            //     main.filterHelper.timerId = setTimeout(function () {
            //         _this.sendFilterDelayed(_this, url, filtersStr)
            //     }, main.filterHelper.delay)
            // }
        }
    }

    sendFilterDelayed(_this, url, filtersStr) {
        _this.filterAjax(url, function(json) {
            if (_this.filterResultBlock) {
                _this.filterResultBlock.html(json.html);
                $(document).trigger("updateFilters");
            }
            if (_this.isMap) {
                var configOverride = {}

                if (_this.isOneComplexMarkerInitOpen) {
                    configOverride.zoom = 11;
                    json.map_items[Math.floor(Math.random() * json.map_items.length)].custom_setting = {
                        initially_opened_window: true,
                    }
                }

                generateConstructorMap('complexes', _this.filterMapResultSelector, json, configOverride)
            }
            if (json.fs) {
                for (let filterName in json.fs) {
                    let values = json.fs[filterName];
                    for (let value in values) {
                        let count = values[value];
                        let label = null;
                        let input = $(`input[name="${filterName}"][value="${value}"]`);
                        if (input.is('[type="checkbox"]') || input.is('[type="radio"]')) {
                            if (input.attr('id') === undefined) {
                                console.warn('Input doesn\'t have id attr', input);
                                continue;
                            }

                            let inputId = input.attr('id');
                            label = $(`label[for="${inputId}"]`)
                        }

                        if (count > 0) {
                            input.removeClass('disabled');
                            if (label !== null) {
                                label.removeClass('disabled');
                            }
                        } else {
                            if ((!input.is('[type=checkbox]') && !input.is('[type=radio]')) || !input.prop('checked')) {
                                input.addClass('disabled');
                                if (label !== null) {
                                    label.addClass('disabled');
                                }
                            }
                        }
                    }
                }
            }
            _this.setTotals(json);
            if (json.selection && json.selection !== "") {
                _this.refreshMeta(json.selection);
            } else if (!_this.checkDeletedLastFilter(_this.filters)) {
                _this.renderBreadcrumbs(_this.baseBreadcrumbs)
                if (_this.IsClearFilter) {
                    window.history.replaceState({}, document.title || $('input[name=slug]').val(),
                        $('.clear-filter').attr('href'));
                } else {
                    window.history.replaceState({}, document.title || $('input[name=slug]').val(),
                        $('.clear-filter').attr('href') + '?' + filtersStr);

                }
            }
            _this.switchLoaders(false);
            _this.IsClearFilter = false;
        })
    }

    loadMore(loadMoreBtn) {
        this.filterAjax(this.loadMoreUrl, function(json) {
            loadMoreBtn.replaceWith(json.html);
        });
    }

    loadMapResults() {
        let _this = this;

        _this.filterAjax(_this.filterUrl + window.location.search, function(json) {
            var configOverride = {}

            if (_this.isOneComplexMarkerInitOpen) {
                configOverride.zoom = 11;
                json.map_items[Math.floor(Math.random() * json.map_items.length)].custom_setting = {
                    initially_opened_window: true,
                }
            }

            generateConstructorMap('complexes', _this.filterMapResultSelector, json, configOverride)
            _this.setTotals(json);
        });
    }

    setTotals(json) {
        if (this.filterFoundBlock) {
            this.filterFoundBlock.html(json.total);
        }
        if (this.filterShowBtn) {
            this.filterShowBtn.html(json.popup_total)
        }
        if (json.number_total === 0) {
            if (this.filterResultBlock) this.filterResultBlock.addClass('no-complexes');
            if (this.showBloks) this.showBloks.addClass('hidden-block');
        } else {
            if (this.filterResultBlock) this.filterResultBlock.removeClass('no-complexes');
            if (this.showBloks) this.showBloks.removeClass('hidden-block');

        }
    }

    hideDOMelement() {
        // Скрывает/показывает кнопки "очистить фильтр" на мобильной версии в зависимости от query string
        if (window.innerWidth < 768) {
            var url = new URL(window.location.href);

            if (url.searchParams.has('filters')) {
                $('.complexes-filter__clear-filter').show();
                $('.complexes-filter__block').addClass('complexes-filter__block--space-between');
                $('.popup__filter-bottom').find('.clear-filter').show();
                $('.popup__filter-bottom').find('.show-results.btn').removeClass('full-width');
            } else {
                $('.complexes-filter__clear-filter').hide();
                $('.complexes-filter__block').removeClass('complexes-filter__block--space-between');
                $('.popup__filter-bottom').find('.clear-filter').hide();
                $('.popup__filter-bottom').find('.show-results.btn').addClass('full-width');
            }
        }
    }

    generateFilterTagForElement(el, addCloseBtn) {
        let text = this.filterTagText(el),
            filterName = el.attr('name'),
            filterValue = el.val();

        return this.generateFilterTag(text, filterName, filterValue, addCloseBtn);
    }

    generateFilterTag(text, filterName, filterValue, addCloseBtn, removeCallback) {
        addCloseBtn = addCloseBtn === undefined ? true : addCloseBtn;
        var clearBtnClass = filterName == "clear-all" ? 'filter__tags-clear' : '';
        let tagHtml = `<div class="filter__tags-item ${clearBtnClass}" data-filter-name="${filterName}" data-filter-value="${filterValue}"><span>${text}</span>`;
        if (addCloseBtn) {
            tagHtml += '<i class="filter__tags-close"></i>';
        }

        tagHtml += '</div>';

        let tag = $(tagHtml),
            _this = this,
            removeBtn = tag.find('.filter__tags-close');
        if (!removeBtn.length) {
            removeBtn = tag;
        }

        removeBtn.on('click', function() {
            let tag = $(this).closest('.filter__tags-item');
            if ($('.filter__tags-item .filter__tags-close').length === 1) {
                history.pushState('', '', $('.clear-filter').attr('href'))
                _this.clearFilter();
            } else {
                if (removeCallback === undefined) {
                    let input = $(`input[name="${tag.data('filter-name')}"][value="${tag.data('filter-value')}"]`);
                    if (input.is('[type=checkbox]') || input.is('[type=radio]')) {
                        input.prop('checked', false).change();
                    } else if (input.is('[type=hidden]')) {
                        input.remove();
                        tag.remove();
                        _this.sendFilter();
                    }
                } else {
                    removeCallback(tag);
                }
                _this.prepareTagItems();
            }

        });
        if (tag.hasClass('filter__tags-clear')) {
            tag.on('click', function() {
                _this.clearFilter()
            })
        }

        return tag;
    }

    getFilterTag(filterName, filterValue) {
        return $(`.filter__tags-item[data-filter-name="${filterName}"][data-filter-value="${filterValue}"]`);
    }

    prepareTagItems() {
        let filterName = 'clear-all',
            filterValue = 'clear',
            tag = this.getFilterTag(filterName, filterValue);
        if ($('.filter__tags-item .filter__tags-close').length > 1) {
            if (!tag.length) {
                tag = this.generateFilterTag(gInitialData.filterLang.clear_all, filterName, filterValue, false);
                // tag.on('click', function () {
                //     window.location.href = $('.clear-filter').attr('href');
                // });

                this.tagsBlock.prepend(tag);
            }
        } else {
            tag.remove();
        }
    }

    filterTagText(el) {
        if (el.data('tag-text') !== undefined) {
            return el.data('tag-text');
        }

        if (el.is('[type=radio]') || el.is('[type=checkbox]')) {
            let id = el.attr('id');
            return $(`label[for="${id}"]`).first().text();
        }

        return el.val();
    }

    addTagToBlock(tag, append) {
        if (this.tagsBlock) {
            let tagName = tag.data('filter-name'),
                tagValue = tag.data('filter-value');
            if (['sort_type', 'sort_direction'].indexOf(tagName) === -1) {
                if (!this.getFilterTag(tagName, tagValue).length) {
                    if (append) {
                        this.tagsBlock.append(tag);
                    } else {
                        this.tagsBlock.prepend(tag);
                    }
                }
            }

            this.prepareTagItems();
        }
    }
}

$(document).ready(function() {
    $('.top_filter_tag').click(function() {
        var filterName = $(this).data('filter-name')
        var filterValue = $(this).data('filter-value')
        var filterInput = $('input[name="' + filterName + '"').filter(function() {
            return this.value == filterValue
        });
        if (filterInput.length > 0) {
            filterInput[0].click();
        }
    })
    $('.top_filter_tag').each(function(index, element) {
        var tag = $(this)
        var filterName = $(element).data('filter-name')
        var filterValue = $(element).data('filter-value')
        var filterInput = $('input[name="' + filterName + '"').filter(function() {
            return this.value == filterValue
        });
        filterInput.change(function() {
            if (filterInput.prop('checked') === true) {
                $(tag).addClass('active')
            } else {
                $(tag).removeClass('active')
            }
        })
        if (filterInput.prop('checked') === true) {
            $(element).addClass('active')
        } else {
            $(element).removeClass('active')
        }
    })
})
