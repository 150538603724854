import general from "./general";
import complexes from "./complexes";
import { toggleSwitch, infrastructureMap } from "./functions";

const complexShow = {
    init: function () {
        document.addEventListener("DOMContentLoaded", () => {
            // general.faq();
            // complexShow.mainSlider();
            complexShow.layoutsComplexes();
            complexes.cardsSliderInit();
            complexShow.generalPlan();
            complexShow.developerBlockLoadmore();
            complexShow.ComplexPlaces();
            complexShow.mortgageSliders();
            complexShow.selectionsBlockSliderChange();
            complexShow.selectionsBlockLoadmore();
            complexShow.stocksLoadmore();
            complexShow.paymentSwitch();
            complexShow.articleBlock();
            toggleSwitch();
            infrastructureMap('#complex-infrastructure');
        });
    },
    mainSlider: function () {
        let mainSlider = $(".complex-main__slider");
        let dots = $(".complex-main__dots-item");
        let seconds = 5000;

        // Проверяем наличие нужных тегов для работы слайдера
        if (mainSlider.length && dots.length) {

            var timer;

            document.addEventListener('visibilitychange', () => {
                if (document.visibilityState === 'visible') {
                    // Страница видима
                    // Запускаем таймер
                    timer = setTimeout(switchSlider, seconds);
                } else {
                    // Страница скрыта
                    // Останавливаем таймер чтобы не тратить ресурсы
                    clearInterval(timer);
                }
            });

            mainSlider.flickity({
                cellAlign: "left",
                contain: true,
                prevNextButtons: false,
                pageDots: false,
                autoPlay: false,
                wrapAround: true,
            }).on('change.flickity', function(event, index) {
                // Произошло перелистывание слайда.
                // Запускаем функцию изменения слайдера с индексом активного слайда
                changeSlider(index);
            }).on('dragStart.flickity', function(){
                // Пользователь перелистывает слайд. Останавливаем таймер
                clearInterval(timer);
            }).on('dragEnd.flickity', function(){
                // Пользователь завершил перелистывание слайда. Запускаем таймер
                timer = setTimeout(switchSlider, seconds);
            });

            let changeSlider = (index) => {
                var count_items = 0;
                while (count_items <= (dots.length - 1)) {
                    if (count_items < index) {
                        $('.complex-main__dots-item:eq(' + count_items + ')')
                                .removeClass("active-animate")
                                .addClass("active");
                    }
                    if (count_items == index) {
                        $('.complex-main__dots-item:eq(' + count_items + ')').removeClass("active").delay(100).queue(function(){
                            $(this).addClass("active-animate");
                            $(this).dequeue();
                        });
                    }
                    if (count_items > index) {
                        $('.complex-main__dots-item:eq(' + count_items + ')')
                                .removeClass("active-animate")
                                .removeClass("active");
                    }
                    count_items++;
                }
            };

            let switchSlider = () => {
                // Перелистываем слайд
                mainSlider.flickity("next");
                // Запускаем таймер
                timer = setTimeout(switchSlider, seconds);
            };

            // Запускаем таймер
            timer = setTimeout(switchSlider, seconds);
            // После загрузки страницы запускаем функцию изменения слайдера с индексом 0.
            // Это означает что активен первый слайд
            changeSlider(0);
        }
    },
    layoutsComplexes: function () {
        if ($(window).width() < 768) return;

        var canvas = $("#layout-canvas");

        let currentRooms = "1";

        if ($(".complex-layouts__item").length == 0 || !canvas) return;

        var containerTop = $(".complex-layouts").offset().top,
            containerBottom = containerTop + $(".complex-layouts").height(),
            containerInViewport = false,
            animationStarted = false,
            currentFrameCount = 0,
            canvasUpdateInterval = null,
            imagesArr = [],
            frameCount = 0,
            initFrame = 0,
            imgLayout = null;

        setTimeout(function () {
            containerTop = $(".complex-layouts").offset().top;
            containerBottom = containerTop + $(".complex-layouts").height();
        }, 1000);

        function updateImage(i) {
            $('.img-layout-canvas-room').css("display", "none");
            $('.img-layout-canvas-room-' + currentRooms).eq(i).css("display", "inline");
        }

        $(window).scroll(function () {
            var windowTop = $(window).scrollTop();
            var windowBottom = windowTop + $(window).height();

            if (windowBottom > containerTop && windowTop < containerBottom) {
                containerInViewport = true;
                if (initFrame == 0) {
                    $(".complex-layouts__item").first().trigger("mouseenter");
                    initFrame++;
                }
            } else {
                containerInViewport = false;
            }
        });

        $(".complex-layouts__item").on("mouseenter", function (event) {
            currentRooms = $(this).data("rooms");
            var activeLayoutCoords =
                    $(this).offset().top -
                    $(".complex-layouts__container").offset().top +
                    $(this).height() / 2,
                text = $(this).attr("data-text");

            $(".complex-layouts__desc-arrow").addClass("show");
            $(".complex-layouts__desc-arrow").css("top", activeLayoutCoords);
            $(".complex-layouts__desc-container").addClass("hidden");

            if (!animationStarted) {
                animationStarted = true;
                setTimeout(function () {
                    $(".complex-layouts__desc-container").addClass("show");
                    $(".complex-layouts__text span").html(text);
                }, 300);
                setTimeout(function () {
                    $(".complex-layouts__desc-container").removeClass(
                        "hidden show"
                    );
                    animationStarted = false;
                }, 600);
            } else {
                $(".complex-layouts__text span").html(text);
            }

            imagesArr = $(".img-layout-canvas-room-" + currentRooms);
            frameCount = imagesArr.length;

            clearInterval(canvasUpdateInterval);
            currentFrameCount = 0;

            setTimeout(function () {
                updateImage(currentFrameCount);
            }, 300);

            if (frameCount == 1) return;

            canvasUpdateInterval = setInterval(function () {
                if (!containerInViewport) return;
                if (currentFrameCount < frameCount - 1) {
                    currentFrameCount++;
                } else {
                    currentFrameCount = 0;
                }
                $("#layout-canvas").animate(
                    {
                        opacity: 0,
                    },
                    200,
                    function () {
                        updateImage(currentFrameCount);
                        $("#layout-canvas").animate(
                            {
                                opacity: 1,
                            },
                            200
                        );
                    }
                );
            }, 2000);
        });
    },
    generalPlan: function () {
        $(".general-plan__dot").on("click", function () {
            let cardDotId = $(this).data("dot-id");
            let renderCard = $(`[data-index="${cardDotId}"]`);

            $(".general-plan__card").removeClass("show").css("opacity", 0);
            $(".general-plan__dot").removeClass("active");

            var left = adjustPercentage($(this)[0].style.left, 2),
                top = adjustPercentage($(this)[0].style.top, -10);

            renderCard.css({
                left: left,
                top: top,
            });

            setTimeout(() => {
                if (renderCard.offset().left + renderCard.width() > $(window).width()) {
                    left = adjustPercentage(left, -17.3);
                    renderCard.css({
                        left: left,
                    });
                }
            }, 100);

            function adjustPercentage(inputString, changeAmount) {
                let originalValue = parseInt(inputString, 10);
                return originalValue + changeAmount + "%";
            }

            renderCard.addClass("show");
            renderCard.animate({ opacity: 1 });

            $(this).addClass("active");

            $(this).mouseleave(function () {
                $(document).click(function (event) {
                    if (
                        !event.target.closest(".general-plan__card") &&
                        !event.target.closest(".general-plan__dot")
                    ) {
                        renderCard.removeClass("show");
                    }
                });
            });
        });

        $(document).on("click", function (event) {
            if (
                !event.target.closest(".general-plan__dot") &&
                !event.target.closest(".general-plan__card")
            ) {
                $(".general-plan__dot").removeClass("active");
            }
        });
    },
    developerBlockLoadmore: function () {
        if ($(window).width() > 767) {
            $(".developer-block .complex-card").slice(0, 6).show();
        }
        updateButtonText(
            $(".developer-block .complex-card:hidden"),
            $(".developer-block .btn"),
            6
        );

        $(".developer-block .btn").on("click", function (e) {
            showEls($(".developer-block .complex-card:hidden"));
            updateButtonText(
                $(".developer-block .complex-card:hidden"),
                $(this),
                6
            );
        });

        function showEls(hiddenElementsArray, loadCount) {
            hiddenElementsArray.slice(0, loadCount).slideDown();
        }

        function updateButtonText(hiddenElementsArray, btn, loadCount) {
            let elCount = hiddenElementsArray.length;
            btn.find(".count-remain").text(elCount);
            if (elCount == 0) {
                btn.fadeOut("slow");
            }
            if (elCount < loadCount) {
                btn.find(".count-show").text(elCount);
            }
        }
    },
    selectionsBlockLoadmore: function () {
        if ($(window).width() < 767) {
            $(".scroll__wrapp-1 .selections-block__item").slice(0, 4).css('display','flex');
            $(".scroll__wrapp-2 .selections-block__item").slice(0, 4).css('display','flex');
        }
        updateButtonText(
            $(".scroll__wrapp-1 .selections-block__item:hidden"),
            $(".scroll__wrapp-1 .selections-block__slider .btn_full"),
            4
        );
        updateButtonText(
            $(".scroll__wrapp-2 .selections-block__item:hidden"),
            $(".scroll__wrapp-2 .selections-block__slider .btn_full"),
            4
        );

        $(".scroll__wrapp-1 .btn_full").on("click", function (e) {
            showEls($(".scroll__wrapp-1 .selections-block__item:hidden"), 4);
            updateButtonText(
                $(".scroll__wrapp-1 .selections-block__item:hidden"),
                $(this),
                4
            );
        });
        $(".scroll__wrapp-2 .btn_full").on("click", function (e) {
            showEls($(".scroll__wrapp-2 .selections-block__item:hidden"), 4);
            updateButtonText(
                $(".scroll__wrapp-2 .selections-block__item:hidden"),
                $(this),
                4
            );
        });

        function showEls(hiddenElementsArray, loadCount) {
            hiddenElementsArray.slice(0, loadCount).slideDown({
                start: function () {
                    $(this).css({
                        display: "flex",
                    });
                },
            });
        }

        function updateButtonText(hiddenElementsArray, btn, loadCount) {
            let elCount = hiddenElementsArray.length;
            btn.find(".count-remain").text(elCount);
            if (elCount == 0) {
                btn.fadeOut("slow");
            }
            if (elCount < loadCount) {
                btn.find(".count-show").text(elCount);
            }
        }
    },
    ComplexPlaces: function () {
        const cards = $(".complex-places__grid-item")

        // При загрузке страницы показываем наименьшие доступные значения
        cards.each((i, card) => {
            const type = getLowestType(card)
            // Если в карточке доступно хотя бы одно время, показываем её
            if (!type) return
            toggleSeveralTimesByType(card, type)
        })

        // Переключалка
        $('.complex-places__switch').change(function () {
            const type = $(this).val();

            // Если в карточке прописано время только пешком - показываем карточки под иконкой "пешком"
            // Если в карточке прописано время только на машине - показываем карточки под иконкой "на машине"
            // Если в карточке показываем время и пешком и на машине - показываем такие карточки и в иконках "пешком" и "на машине" - но при этом отображаем только то время, что соответствует иконке
            // Под иконкой "пешком и на машине" - показываем все места

            cards.each((i, card) => {
                // Если нет доступного времени по текущему типу, скрываем карточку
                if (!isAvailableForType(card, type)) {
                    $(card).hide()
                    return
                }

                toggleSeveralTimesByType(card, type)
            })

            // Сортируем по времени
            sortByTime(cards, type)
        })

        /**
         * Получить наименьший доступный тип времени
         * убирает значения с нулевым временем
         *
         * @param card
         * @returns {undefined|string}
         */
        function getLowestType(card) {
            const carTime = $(card).data('car-time');
            const walkTime = $(card).data('walk-time');

            if (carTime > 0 && carTime < walkTime || carTime > 0 && walkTime <= 0) {
                return 'car'
            } else if (walkTime > 0 && walkTime < carTime || walkTime > 0 && carTime <= 0) {
                return 'walk'
            } else if (carTime > 0 && walkTime > 0 && carTime === walkTime) {
                return 'both'
            }

            return undefined
        }

        /**
         * Получить противоположный тип
         *
         * @param type
         * @returns {string}
         */
        function getOppositeType(type) {
            if (!type) return
            return type === 'car' ? 'walk' : 'car'
        }

        /**
         * Проверяет доступна ли карточка для показа по типу
         * убирает значения с нулевым временем
         *
         * @param card
         * @param type
         */
        function isAvailableForType(card, type) {
            if (type === 'car' || type === 'walk') {
                return $(card).data(type + '-time') > 0
            }

            return $(card).data('car-time') > 0 || $(card).data('walk-time') > 0
        }

        /**
         * Показать/скрыть время по типу
         *
         * @param card
         * @param type
         * @param show
         */
        function toggleTimeByType(card, type, show = true) {
            if (!type || $(card).data(type + '-time') <= 0) return
            const selector = `.complex-places__grid-item__time__wrapper-${type}`
            if (show)
                $(card).find(selector).addClass('active');
            else
                $(card).find(selector).removeClass('active');
        }

        /**
         * Показывает и скрывает блок с несколькими временами
         *
         * @param card
         * @param type
         */
        function toggleSeveralTimesByType(card, type) {
            const time = $(card).find(".complex-places__grid-item__time")

            $(card).show()
            // Если показываем либо для машины или пешком
            if (type === 'car' || type === 'walk') {
                time.removeClass('row');
                toggleTimeByType(card, type, true) // Показываем доступное время
                toggleTimeByType(card, getOppositeType(type), false) // Другое время скрываем
            } else {
                time.addClass('row');
                toggleTimeByType(card, 'car', true)
                toggleTimeByType(card, 'walk', true)
            }
        }

        /**
         * Сортировка карточек
         *
         * @param cards
         * @param type
         */
        function sortByTime(cards, type) {
            cards.sort(function (a, b) {
                if (type === "car" || type === "walk") {
                    var timeA = $(a).data(type + "-time");
                    var timeB = $(b).data(type + "-time");
                } else {
                    var timeA = $(a).data("walk-time") <= 15 ? $(a).data("walk-time") : $(a).data("car-time");
                    var timeB = $(b).data("walk-time") <= 15 ? $(b).data("walk-time") : $(b).data("car-time");
                }

                // Сортировка в порядке убывания
                return timeA - timeB;
            });

            cards.detach().appendTo(".complex-places__grid");
        }
    },
    mortgageSliders: function () {
        function trimSpace(t, e) {
            return t.replace(/\s/g, "").trim(), e && (t *= 1), t;
        }
        function numberValidate(t) {
            var e = t || window.event,
                i = e.keyCode || e.which;
            i = String.fromCharCode(i);
            /[0-9]/.test(i) ||
                ((e.returnValue = !1), e.preventDefault && e.preventDefault());
        }
        $(".complex-mortgage__banks-row").click(function (e) {
            $(".complex-mortgage__banks-row ").removeClass("active");
            $(this).addClass("active");
            $("[range-wrapp]")
                .find('input, span[contenteditable="true"]')
                .change();
        });
        $(".complex-mortgage__switch").change(function (e) {
            let price = $(this).val();
            $(".complex-mortgage__sliders-item:first-child")
                .find('span[contenteditable="true"]')
                .text(priceFormat(price));
            $("[range-wrapp]")
                .find('input, span[contenteditable="true"]')
                .change();
        });

       setTimeout(() => {
           $(".complex-mortgage__switch-item:first").click();
       }, 300)

        if ($(window).width() < 767) {
            $(".complex-mortgage__banks-row")
                .slice(0, 3)
                .css("display", "flex");
            if ($(".complex-mortgage__banks-row:hidden").length == 0) {
                $(".complex-mortgage .btn_loadmore").hide();
            }
        }

        $(".complex-mortgage .btn_loadmore").on("click", function (e) {
            $(".complex-mortgage__banks-row:hidden")
                .slice(0, 3)
                .css("display", "flex");
            if ($(".complex-mortgage__banks-row:hidden").length == 0) {
                $(".complex-mortgage .btn_loadmore").hide();
            }
        });
        if (0 != $("[data-slider]").length) {
            if ($(".complex-mortgage__sliders-item_fixed").length) {
                var t = $(".complex-mortgage__sliders-item_fixed").find(
                    ".complex-mortgage__sliders-value span"
                );
                t.siblings('input[type="hidden"]').val(t.text()),
                    t.text(window.priceFormat(parseInt(t.text())));
            }
            $("[data-slider]").each(function (t, e) {
                !(function (t) {
                    var e = $(t).data("slider"),
                        i = function () {
                            var t = parseInt(
                                    $(".complex-mortgage__cost")
                                        .val()
                                        .replace(/\s+/g, "")
                                ),
                                e = parseInt(
                                    $(".complex-mortgage__initial")
                                        .val()
                                        .replace(/\s+/g, "")
                                ),
                                i = parseInt(
                                    $(".complex-mortgage__years")
                                        .val()
                                        .replace(/\s+/g, "")
                                );
                            $(".complex-mortgage__banks-row").each(function () {
                                var n = parseFloat(
                                        $(this)
                                            .find("[mortgage-percent]")
                                            .text()
                                            .replace(",", ".")
                                    ),
                                    a = (function (t, e, i, n, a) {
                                        var o = 12 * i,
                                            s = Math.min(e, t),
                                            r = a / 1200,
                                            l = Math.max(t * n, s),
                                            c = t - l;
                                        if (c <= 0) return "0";
                                        var d =
                                                (r * Math.pow(1 + r, o)) /
                                                (Math.pow(1 + r, o) - 1),
                                            u = Math.round(d * c);
                                        u =
                                            void 0 === u.toLocaleString
                                                ? gFormatPrice(u)
                                                : u.toLocaleString();
                                        return u;
                                    })(t, e, i, 0, n);
                                $(this).find("[mortgage-payment]").html(a);
                                if (
                                    $(this).hasClass("active") &&
                                    a != "не число"
                                ) {
                                    $(".complex-mortgage__summary-count").html(
                                        priceFormat(
                                            +a.replace(/\s+/g, "") * 12 * +i +
                                                +e
                                        )
                                    );
                                }
                            });
                        },
                        n = {
                            start: [128928382],
                            connect: "lower",
                            range: {
                                min: 0,
                                max: 3e8,
                            },
                            format: {
                                to: function (t) {
                                    return parseInt(t);
                                },
                                from: function (t) {
                                    return parseInt(t);
                                },
                            },
                        },
                        a = {
                            start: [1e6],
                            connect: "lower",
                            range: {
                                min: 0,
                                max: 1e8,
                            },
                            format: {
                                to: function (t) {
                                    return parseInt(t);
                                },
                                from: function (t) {
                                    return parseInt(t);
                                },
                            },
                        },
                        o = {
                            start: [20],
                            connect: "lower",
                            range: {
                                min: 1,
                                max: 30,
                            },
                            format: {
                                to: function (t) {
                                    return parseInt(t);
                                },
                                from: function (t) {
                                    return parseInt(t);
                                },
                            },
                        };
                    switch (e) {
                        case "mortgage-price":
                            noUiSlider.create(t, n);
                            break;
                        case "mortgage-payment":
                            noUiSlider.create(t, a);
                            break;
                        case "mortgage-term":
                            noUiSlider.create(t, o);
                            break;
                        default:
                            return !1;
                    }
                    function s(e) {
                        var i = $(t).closest("[range-wrapp]").find("input");
                        if ("hidden" == i.attr("type"))
                            var n = $(i).siblings("span");
                        var a = void 0 !== $(i).data("unit");
                        if (
                            ($(i).val(priceFormat(e, a)),
                            $(i).attr("data-current-value", e),
                            n)
                        ) {
                            var o =
                                    !("" == $(i).data("unit") || !a) &&
                                    `<span class="currency">${$(i).data(
                                        "unit"
                                    )}</span>`,
                                s =
                                    void 0 !== $(i).attr("data-choice-words") &&
                                    $(i).attr("data-choice-words");
                            if (s) {
                                s = s.toString().split(",");
                                var r = complexShow.transChoice($(i).val(), s);
                                o =
                                    !(
                                        "" == $(i).attr("data-choice-words") ||
                                        !a
                                    ) && `<span class="currency">${r}</span>`;
                            }
                            n.html(
                                `\n                        ${$(i).val()} ${
                                    o || ""
                                }\n                    `
                            );
                        }
                    }
                    t.noUiSlider.on("update", function (t) {
                        s(t), i();
                    });
                })(e);
            }),
                $("[range-wrapp]")
                    .find('input, span[contenteditable="true"]')
                    .on("change focusout", function (t) {
                        var e = t.target
                            .closest("[range-wrapp]")
                            .querySelector("[data-slider]");
                        switch (!0) {
                            case $(this).is("input"):
                                var i = $(this)
                                    .val()
                                    .replace(/[^0-9]+/g, "");
                                break;
                            case $(this).is("span"):
                                i = $(this)
                                    .text()
                                    .replace(/[^0-9]+/g, "");
                                $(this).siblings("input").val(trimSpace(i, !0));
                                break;
                            default:
                                return !1;
                        }
                        if (
                            ((i = trimSpace(i, !0)),
                            "" != $(this).attr("input-range") &&
                                null != $(this).attr("input-range"))
                        )
                            switch ($(this).attr("input-range")) {
                                case "min":
                                    e.noUiSlider.set([i, null]);
                                    break;
                                case "max":
                                    e.noUiSlider.set([null, i]);
                                    break;
                                default:
                                    return !1;
                            }
                        else e.noUiSlider.set(i);
                    }),
                $("[range-wrapp] input,span").on("keypress", function (t) {
                    13 == t.keyCode && $(this).blur(), numberValidate(t);
                }),
                $(".lots-main__mortgage-btn__price").length &&
                    $(".lots-main__mortgage-btn__price").text(
                        $("[mortgage-payment]").first().text()
                    );
        }
    },
    transChoice: function (t, e) {
        var i = (t = Math.abs(t) % 100) % 10;
        return t > 10 && t < 20
            ? e[2]
            : i > 1 && i < 5
            ? e[1]
            : 1 == i
            ? e[0]
            : e[2];
    },
    selectionsBlockSliderChange: function () {
        $(".selections-block .country-dropdown__link").click(function (e) {
            $(".selections-block .country-dropdown__link").removeClass("active");
            $(this).addClass("active");
            $(".selections-block .country-dropdown__drop-btn span").text(
                $(this).text()
            );
            $(".selections-block .country-dropdown__checkbox").prop(
                "checked",
                false
            );
            let idx = $(this).index() + 1;
            $(".selections-block .scroll__wrapp").hide();
            console.log(idx);
            $(".selections-block .scroll__wrapp-" + idx).show();
            $(document).trigger("scroll-bar-resize");
            window.dispatchEvent(new Event("resize"));
        });
    },
    paymentSwitch: function () {
        let startval = $(".payment-plan__switch:checked").val();
        $(`[data-show="${startval}"]`).show();
        $(".payment-plan__switch").change(function (e) {
            let val = $(this).val();
            $(".payment-plan__results-col").hide();
            $(`[data-show="${val}"]`).show();
        });
    },
    stocksLoadmore: function () {

        if ($(window).width() > 767) {
             $(".complex-stocks__stock-item").hide();
            $(".complex-stocks__stock-item").slice(0, 3).show();
        }
        updateButtonText(
            $(".complex-stocks__stock-item:hidden"),
            $(".complex-stocks__loadmore-btn"),
            5
        );

        $(".complex-stocks__loadmore-btn").on("click", function (e) {
            showEls($(".complex-stocks__stock-item:hidden"));
            updateButtonText(
                $(".complex-stocks__stock-item:hidden"),
                $(this),
                5
            );
        });

        function showEls(hiddenElementsArray, loadCount) {
            hiddenElementsArray.slice(0, loadCount).slideDown();
        }

        function updateButtonText(hiddenElementsArray, btn, loadCount) {
            let elCount = hiddenElementsArray.length;
            btn.find(".count-remain").text(elCount);
            if (elCount == 0) {
                btn.fadeOut("slow");
            }
            if (elCount < loadCount) {
                btn.find(".count-show").text(elCount);
            }
        }
    },
    articleBlock: function () {
        if ($('.complex-article').length == 0) return;
        const container = $('.complex-article__content');
        const btn = container.find('.complex-article__btn');
        let btnText = '';
        
        btn.on('click', () => {
            container.toggleClass('show');
            btnText = btn.text();
            btn.text(btn.data('article-btn-text'));
            btn.data('article-btn-text', btnText);
            btn.css('opacity', 0);
            setTimeout(() => {
                btn.css('opacity', 1);
            }, 300);
        });
    }
};
window.priceFormat = function (t, e, i) {
    let n = "rub",
        a = "ru" || "₽",
        o = 'after',
        s = "₽",
        r = "";
    return (
        "before" !== o || e || (r += s),(r += t.toLocaleString("ru-RU")),"after" !== o || e || (r += " " + s),r
    );
};

complexShow.init();

export default complexShow;
